import React, { useEffect, useState } from "react";
import { withI18n } from "react-i18next";
import styled from "styled-components";
// @ts-ignore
import { CWBDropdown, moment } from "cwb-react";
import { SectionTitle } from "./StyledComponents";

type Props = {
  t: any;
  id?: string;
  className?: string;
  name?: string;
  value: any;
  onChange: (event: any) => void;
};

type Option = {
  label: string;
  value: number | null;
};

const BirthDatePicker: React.FC<Props> = ({
  t,
  id,
  className,
  name,
  value,
  onChange: handleChange,
}) => {
  const defaultOption: Option = { label: "", value: null };

  const [yearOptions, setYearOptions] = useState<Option[]>([]);
  const [monthOptions, setMonthOptions] = useState<Option[]>([]);
  const [dayOptions, setDayOptions] = useState<Option[]>([]);

  const [day, setDay] = useState<Option>(defaultOption);
  const [month, setMonth] = useState<Option>(defaultOption);
  const [year, setYear] = useState<Option>(defaultOption);

  useEffect(() => {
    const validYears: Option[] = [];
    const validMonths: Option[] = [];

    for (let year = moment.moment().year(); year >= 1900; year--) {
      validYears.push({
        label: year.toString(),
        value: year,
      });
    }

    for (let month = 0; month < 12; month++) {
      const monthOption: string = moment.moment().month(month).format("MMMM");
      validMonths.push({
        label: monthOption,
        value: month,
      });
    }

    setYearOptions(validYears);
    setMonthOptions(validMonths);
  }, []);

  useEffect(() => {
    let maxDay = 31;
    const validDays: Option[] = [];

    if (month.value !== null && year.value !== null && month.value.toString() !== "" && year.value.toString() !== "") {
      const dateString = `${year.value}-${(month.value + 1).toString().padStart(2, "0")}`;
      maxDay = Number(moment.moment(dateString).endOf("month").format("D"));
      if (day.value > maxDay) {
        setDay({
          label: maxDay.toString(),
          value: maxDay,
        });
      }
    }

    for (let day = 1; day <= maxDay; day++) {
      validDays.push({
        label: day.toString(),
        value: day,
      });
    }

    setDayOptions(validDays);

    if (day.value && day.value > maxDay) {
      setDay({label: maxDay.toString(), value: maxDay });
    }
  }, [day, month, year]);

  useEffect(() => {
    if (
        (day.value !== null && month.value !== null && year.value !== null) &&
        (typeof(day.value) !== 'undefined' && typeof(month.value) !== 'undefined' && typeof(year.value) !== 'undefined')
      ) {
      const dateString = `${year.value}-${(month.value + 1).toString().padStart(2, '0')}-${day.value.toString().padStart(2, '0')}`;
      const event = {
        target: {
          name,
          value: new Date(moment.moment(dateString).toDate()),
        },
      };

      handleChange(event);
    }
  }, [name, day, month, year, handleChange]);

  useEffect(() => {
    if (
      value != null &&
      (
        (day.value == null || typeof(day.value) === 'undefined') &&
        (month.value == null|| typeof(month.value) === 'undefined') &&
        (year.value == null) ||  typeof(year.value) === 'undefined')
      )
    {
      const s = value?.split(/[//-]+/);
      if (moment.moment(value).isValid() && s.length >= 3) {
        var mDate = moment.moment(value);
        setDay({ label: mDate.date().toString(), value: mDate.date() });
        setMonth({ label: mDate.format("MMMM"), value: mDate.month() });
        setYear({ label: mDate.year().toString(), value: mDate.year() });
      } else {
        if (s[1])
          setDay({ label: s[1], value: Number(s[1]) });
        if (s[0] && (/^-?\d+$/.test(s[0])))
          setMonth({ label: moment.moment().month(Number(s[0]) -1).format("MMMM"), value: Number(s[0]) });
        if (s[2])
          setYear({ label: s[2], value: Number(s[2]) });
      }
    }
  }, [value, day?.value, month?.value, year?.value]);

  return (
    <Wrapper id={id} className={className}>
      <Container>
        <StyledSectionTitle>{t("Day")}</StyledSectionTitle>
        <CWBDropdown
          items={dayOptions}
          placeholder=""
          value={day}
          onChange={(option: Option) => setDay(option)}
        />
      </Container>
      <Container>
        <StyledSectionTitle>{t("Month")}</StyledSectionTitle>
        <CWBDropdown
          items={monthOptions.map(m => ({ ...m, label: t(m.label) }))}
          placeholder=""
          value={month}
          onChange={(option: Option) => setMonth(option)}
        />
      </Container>
      <Container>
        <StyledSectionTitle>{t("Year")}</StyledSectionTitle>
        <CWBDropdown
          items={yearOptions}
          placeholder=""
          value={year}
          onChange={(option: Option) => setYear(option)}
        />
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-end;
  justify-content: space-between;

  & > div + div {
    margin-left: ${(p) => p.theme.spacing(2)};
  }
`;

const Container = styled.div`
  &:nth-of-type(1) {
    flex-grow: 1;

    & > div > div {
      min-width: unset;
    }
  }

  &:nth-of-type(2) {
    flex-grow: 4;

    & > div > div {
      min-width: unset;
    }
  }

  &:nth-of-type(3) {
    flex-grow: 2;

    & > div > div {
      min-width: unset;
    }
  }

  && > div > div > div {
    min-height: ${(p) => p.theme.spacing(5)};
  }
`;

const StyledSectionTitle = styled(SectionTitle)`
  margin-bottom: ${(p) => p.theme.spacing(1)};
`;

export default withI18n()(BirthDatePicker);
