import React from "react";
import qs from "query-string";
import { getI18n, withI18n } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";

import {
  NameMaxLength,
  getSessionId,
  getCurUserSignUp,
  getUserActorSignUpId,
} from "../../../helpers";
import BirthDatePicker from "../../Common/BirthDatePicker";
import Button from "../../Common/Button";
import Footer from "../../Common/Footer";
import Modal from "../../Common/Modal";
import PageContainer from "../../Common/PageContainer";
import Tooltip from "../../Common/Tooltip";
import Typography from "../../Common/Typography";
import {
  Section,
  SectionTitle,
  StyledInput,
  SmallTextError,
  Spinner,
  CheckboxWrapperStage,
  StyledCheckbox,
} from "../../Common/StyledComponents";
import CWBLabsActorStageNameStep from "components/CWBLabs/ActorStageNameStep";
import {
  IActorStageName,
  IActorError,
  IActorAccountDto,
} from "../../../shared/api/dtos/IActorDto";
import { iRootState } from "../../../store";
import { nameRegex } from "helpers/validations";
// @ts-ignore
import * as Sentry from "@sentry/react";
// @ts-ignore
import moment from "moment";

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
}

interface IState {
  fields: any;
  isFormSubmitClicked: boolean;
  ageChecked: boolean;
}

class ActorStageNameStep extends React.Component<IProps, IState> {
  public state: IState = {
    fields: {
      firstName: "",
      lastName: "",
      middleName: "",
      birthDate: null,
    },
    isFormSubmitClicked: false,
    ageChecked: false,
  };

  componentDidMount() {
    const user: IActorAccountDto = getCurUserSignUp();
    if (getSessionId() && user) {
      if (user.actorStageName) {
        let fields = this.state.fields;
        fields.firstName = user.actorStageName.firstName;
        fields.middleName = user.actorStageName.middleName;
        fields.lastName = user.actorStageName.lastName;
        fields.birthDate = new Date(
          user.actorStageName.birthDate
        ).toLocaleDateString();

        this.setState({ fields });
      }
    } else window.location.replace("/");
  }

  checkValidation() {
    const { t } = this.props;
    let fields: IActorStageName = this.state.fields;
    let errors: IActorError = {} as IActorError;

    if (!fields.firstName) {
      errors.FirstName = t("First Name is required");
    } else if (fields.firstName.trim().length < 2) {
      errors.FirstName = t("Minimum 2 characters");
    } else if (!nameRegex.test(fields.firstName)) {
      errors.FirstName = t(
        "Name can only contain letters, spaces, -, ' and `."
      );
    }

    if (fields.middleName) {
      // if (fields.middleName.trim().length < 2) {
      //   errors.MiddleName = t("Minimum 2 characters");
      // } else 
      if (!nameRegex.test(fields.middleName)) {
        errors.MiddleName = t(
          "Name can only contain letters, spaces, -, ' and `."
        );
      }
    }

    if (fields.lastName) {
      if (!nameRegex.test(fields.lastName)) {
        errors.LastName = t(
          "Name can only contain letters, spaces, -, ' and `."
        );
      }

      // if (fields.lastName.trim().length < 2) {
      //   errors.LastName = t("Minimum 2 characters");
      // } else if (!nameRegex.test(fields.lastName)) {
      //   errors.LastName = t(
      //     "Name can only contain letters, spaces, -, ' and `."
      //   );
      // }
    }

    if (!this.state.ageChecked) {
      errors.ageChecked = t("This field is mandatory");
    }

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  handleChange = (e: any) => {
    this.setState(
      {
        fields: {
          ...this.state.fields,
          [e.target.name]: e.target.value,
        },
      },
      () => {
        this.state.isFormSubmitClicked && this.checkValidation();
      }
    );
  };

  handleAgeCheck = (e: any) => {
    this.setState(
      {
        ageChecked: !this.state.ageChecked,
      },
      () => {
        this.state.isFormSubmitClicked && this.checkValidation();
      }
    );
  };

  createStageName = async (e: any) => {
    e.preventDefault();
    this.setState({ isFormSubmitClicked: true });
    if (!this.checkValidation()) return;

    let birthDateString = null;
    if (this.state.fields.birthDate) {

    }

    try {
      birthDateString = this.state.fields.birthDate.toISOString();
    } catch (e) {
      try
      {
        birthDateString = moment(this.state.fields.birthDate).toISOString(true);
      } catch (e2) {
        birthDateString = null;
      }
    }

    const { location } = this.props;

    try {
      await this.props.createStageName({
        ...this.state.fields,
        birthDate: birthDateString,
      });
    } catch (e) {
      Sentry.captureException(e);
    }

    // if (qs.parse(location.search).special) {
    //   this.moveToNextTrial();
    // } else 
    // if (qs.parse(location.search).trial) {
    //   this.moveToNextCode();
    // } else 
    if (qs.parse(location.search).offer) {
      this.moveToNextOffer();
    } else {
      this.moveToNext();
    }
  };

  moveToNext = () => {
    if (this.props.errors.errorMessage)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/4",
        search: this.props.location.search,
      });
    }
  };

  moveToNextTrial = () => {
    if (this.props.errors.errorMessage)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/special/4",
        search: this.props.location.search,
      });
    }
  };

  moveToNextCode = () => {
    if (this.props.errors.errorMessage)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/trial/4",
        search: this.props.location.search,
      });
    }
  };

  moveToNextOffer = () => {
    if (this.props.errors.errorMessage)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/offer/4",
        search: this.props.location.search,
      });
    }
  };

  moveToCheckout = () => {
    if (this.props.errors.errorMessage)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/free-trial/completed",
        search: this.props.location.search,
      });
    }
  };

  render() {
    const { t, location } = this.props;
    const errors = this.props.errors;
    const fields = this.state.fields;
    const ageChecked = this.state.ageChecked;

    const hoverTip = (
      <StyledTooltip
        placement="top"
        tip={t(
          "This field is not mandatory. Some projects require youth and young adults to qualify for project eligibility by being at least a certain age."
        )}
      >
        <img alt="more info" src="../images/info.svg" />
      </StyledTooltip>
    );

    const isLangSpanish = getI18n().language === "es";
    // const special = qs.parse(location.search).special;
    const special = false;

    return qs.parse(location.search).cwblabs ? (
      <CWBLabsActorStageNameStep
        errors={errors}
        fields={fields}
        isLangSpanish={isLangSpanish}
        isLoading={this.props.isLoading}
        onChange={this.handleChange}
        onSubmit={this.createStageName}
        handleAgeCheck={this.handleAgeCheck}
        ageChecked={ageChecked}
      />
    ) : (
      <PageContainer>
        <Wrapper>
          <StyledTypography align="center" component="h1" variant="h2">
            {t("Profile Information")}
          </StyledTypography>
          <StyledForm onSubmit={this.createStageName}>
            <Typography color="darkGrey" component="h2" variant="h5">
              {special
                ? t("Tell us your stage name for casting")
                : t("What's your stage name?")}
            </Typography>
            <Section>
              <SectionTitle>{t("First Name")}</SectionTitle>
              <StyledInput
                name="firstName"
                type="text"
                className={errors.FirstName ? "invalid" : ""}
                maxLength={NameMaxLength}
                onChange={this.handleChange}
                value={fields.firstName}
              />
              <SmallTextError className="error">
                <span>{errors.FirstName}</span>
              </SmallTextError>
            </Section>
            {!isLangSpanish && (
              <Section>
                <SectionTitle>
                  {t("Middle Name (If used professionally)")}
                </SectionTitle>
                <StyledInput
                  name="middleName"
                  type="text"
                  className={errors.MiddleName ? "invalid" : ""}
                  maxLength={NameMaxLength}
                  onChange={this.handleChange}
                  value={fields.middleName}
                />
                <SmallTextError className="error">
                  <span>{errors.MiddleName}</span>
                </SmallTextError>
              </Section>
            )}
            <Section>
              <SectionTitle>{t("Last Name")}</SectionTitle>
              <StyledInput
                type="text"
                name="lastName"
                maxLength={NameMaxLength}
                className={errors.LastName ? "invalid" : ""}
                onChange={this.handleChange}
                value={fields.lastName}
              />
              <SmallTextError className="error">
                <span>{errors.LastName}</span>
              </SmallTextError>
            </Section>
            <StyledH2 color="darkGrey" component="h2" variant="h5">
              {t("When's your birthday?")}
              {hoverTip}
            </StyledH2>
            <Section>
              <BirthDatePicker
                name="birthDate"
                value={fields.birthDate}
                onChange={this.handleChange}
              />
            </Section>
            <Section>
              <CheckboxWrapperStage onClick={this.handleAgeCheck}>
                <AgeCheckbox className="small-cb" isChecked={ageChecked} />
                <StyledLabel>
                  {t(
                    "I am currently at least 18 years old or this is being filled by a parent or guardian"
                  )}
                </StyledLabel>
              </CheckboxWrapperStage>
              <SmallTextError className="error">
                <span>{errors.ageChecked}</span>
              </SmallTextError>
            </Section>
            <Section>
              <StyledButton>
                {t("Continue")}
                {this.props.isLoading && (
                  <Spinner
                    src="../images/spinner.svg"
                    className="spinner-width"
                  />
                )}
              </StyledButton>
            </Section>
          </StyledForm>
        </Wrapper>
        <Footer />
      </PageContainer>
    );
  }
}

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-weight: ${(p) => p.theme["font-weight-600"]};

  width: 480px;
  width: 480px;
  background-color: ${(p) => p.theme.white};
  padding: 40px 80px 50px;

  @media all and (max-width: 520px) {
    width: 100%;
    min-width: 40%;
    padding: 40px;
  }

  & input {
    font-size: ${(p) => p.theme["s-font-size"]};
  }
`;

const StyledTypography = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(3)};
`;

const StyledForm = styled.form`
  width: 100%;
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: 5px;

  & > img {
    width: 18px;
    height: 18px;
    margin-top: -2px;
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 37px;
  font-size: 13px;
  font-weight: 600;
  background-color: ${(p) => p.theme.palette.brand.lightBlue};

  &:hover {
    background-color: ${(p) => p.theme.palette.brand.blue};
  }

  &:active {
    background-color: ${(p) => p.theme.palette.brand.darkBlue};
  }
`;

const StyledH2 = styled(Typography)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;

const StyledLabel = styled(Typography)`
  margin-left: ${(p) => p.theme.spacing(1)};
  font-size: ${(p) => p.theme["xxs-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  color: ${(p) => p.theme.color} !important;
  line-height: 16px;
  cursor: pointer;
`;

const AgeCheckbox = styled(StyledCheckbox)`
  cursor: pointer;
`;

interface StateProps {
  errors: IActorError;
  isLoading: boolean;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.actorModel.errors,
    isLoading: state.actorModel.isLoading,
  };
}

interface DispatchProps {
  setErrors: (errors: IActorError) => void;
  createStageName: (dto: IActorStageName) => void;
  setClientId: (clientId: string) => void;
  setIsLoading: (status: boolean) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    setErrors: dispatch.actorModel.setErrors,
    createStageName: dispatch.actorModel.createStageName,
    setClientId: dispatch.actorModel.setClientId,
    setIsLoading: dispatch.actorModel.setIsLoading,
  };
}

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorStageNameStep)
);
